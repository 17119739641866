<template>
  <layout class="main-cols">
    <div class="container is-fluid lg:ml-0" v-if="getDashboard">
      <div class="columns is-multiline">
        <div class="column is-9 text-left flex">
          <p class="text-2xl font-bold inline-block">Pagos</p>
          <b-select
            class="inline-block ml-3"
            placeholder="Selecciona un periodo de días"
            v-model="periodo"
            @input="setPeriodo"
          >
            <option
              v-for="periodo in periodos_dias"
              :value="periodo.dias"
              :key="periodo.nombre"
              >{{ periodo.nombre }}</option
            >
          </b-select>
          <div v-if="periodo.includes('-')" class="ml-2 mt-1">
            <b-tooltip
              label="Elimina el periodo de favorito"
              size="is-large"
              type="is-dark"
              position="is-top"
            >
              <b-icon
                icon="star"
                class="cursor-pointer has-text-warning"
                @click.native="deletePeriodoCustom"
              />
            </b-tooltip>
          </div>
        </div>
        <div class="column">
          <b-switch
            size="is-medium"
            type="is-primary"
            v-model="cartera"
            passive-type="is-pasivo"
            @input="setPeriodo(periodo)"
          >
            Viendo Cartera {{ cartera ? "activa" : "pasiva" }}
          </b-switch>
        </div>
      </div>
      <div class="columns" v-if="is_custom">
        <validation-observer
          ref="observer"
          v-slot="{ validate }"
          class="w-full text-left flex items-center mt-1"
        >
          <div class="column text-left">
            <valid-input
              rules="required|numeric"
              label="Días antes"
              placeholder="Días antes de hoy..."
              v-model="dias_antes"
              type="number"
              :expanded="true"
            />
          </div>
          <div class="column text-left">
            <valid-input
              rules="required|numeric"
              label="Días despues"
              placeholder="Días despues de hoy..."
              v-model="dias_despues"
              type="number"
              :expanded="true"
            />
          </div>
          <div class="column text-left mt-6">
            <b-tooltip
              label="guarda el periodo para ser utilizado despues"
              size="is-large"
              type="is-dark"
              position="is-top"
            >
              <b-icon
                icon="star"
                class="cursor-pointer mr-4 mt-1"
                @click.native="validate().then(res => setPeriodoCustom(res))"
              />
            </b-tooltip>
            <b-button type="is-primary">Consultar</b-button>
          </div>
        </validation-observer>
      </div>
      <div class="columns is-vcentered">
        <div class="column is-4">
          <p class="text-xl has-text-grey-light font-light">
            Suma de pagos {{ cartera ? "recibidos" : "realizados" }}
          </p>
          <p class="text-3xl font-bold has-text-success">
            {{ getDashboard.total_pagado | currency | hp }}
            <span class="font-light text-lg">MXN</span>
          </p>
        </div>
        <div class="column is-4 relative">
          <donut-chart
            :outer-datos="outerDatos"
            :key="update"
            :inner-datos="innerDatos"
            :cartera="cartera"
          />
        </div>
        <div class="column is-4">
          <p class="text-xl has-text-grey-light font-light">
            Suma de pagos por {{ cartera ? "recibir" : "realizar" }}
          </p>
          <p
            class="text-3xl font-bold"
            :class="{
              'has-text-pasivo': !cartera,
              'has-text-primary': cartera
            }"
          >
            {{ getDashboard.total_pendiente | currency | hp }}
            <span class="font-light text-lg">MXN</span>
          </p>
        </div>
      </div>
      <div class="columns pb-8">
        <div class="column is-6 h-full">
          <p class="text-2xl font-light">
            Pagos {{ cartera ? "recibidos" : "realizados" }}
          </p>
          <div
            class="columns is-multiline mt-8 max-h-20rem overflow-y-auto"
            v-if="pagos_realizados.length > 0"
          >
            <div
              class="column is-12 py-0"
              v-for="(pago, index) in pagos_realizados"
              :key="index"
            >
              <pago-card :pago="pago" :por-pagar="false" />
            </div>
          </div>
          <empty-state
            v-else
            :small="true"
            text-class="text-left"
            imgclass="h-full"
            class="has-background-white-ter py-3 has-border-radius mt-10"
            imagen="/images/placeholder_pagos_verde.svg"
            titulo="Sin pagos"
            subtitulo="realizados o recibidos"
          />
        </div>
        <div class="column is-6 h-full">
          <p class="text-2xl font-light">
            Pagos por {{ cartera ? "recibir" : "realizar" }}
          </p>
          <span
            class="tag is-primary-light cursor-pointer"
            :class="{ 'is-primary': filtroRealizar.includes('disposiciones') }"
            @click="toggleFiltroRealizar('disposiciones')"
            >Disposiciones ({{
              this.getDashboard.pagos_pendientes.disposiciones.length
            }})</span
          >
          <span
            class="tag is-primary-light mx-4 cursor-pointer"
            :class="{ 'is-primary': filtroRealizar.includes('cuentas') }"
            @click="toggleFiltroRealizar('cuentas')"
            >Cuentas cobrar pagar ({{
              this.getDashboard.pagos_pendientes.cuentas.length
            }})</span
          >
          <span
            class="tag is-primary-light cursor-pointer"
            :class="{ 'is-primary': filtroRealizar.includes('garantias') }"
            @click="toggleFiltroRealizar('garantias')"
            >Garantías ({{
              this.getDashboard.pagos_pendientes.garantias.length
            }})</span
          >
          <div
            class="columns is-multiline mt-2 max-h-20rem overflow-y-auto"
            v-if="pagos_pendientes.length > 0"
          >
            <div
              class="column is-12 py-0"
              v-for="(pago, index) in pagos_pendientes"
              :key="index"
            >
              <pago-card :pago="pago" :tipo="pago.tipo" :por-pagar="true" />
            </div>
          </div>
          <empty-state
            v-else
            :small="true"
            text-class="text-left"
            imgclass="h-full"
            class="has-background-white-ter py-3 has-border-radius mt-3"
            imagen="/images/placeholder_pagos_ama.svg"
            titulo="Sin pagos"
            subtitulo="por realizar o recibir"
          />
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import ValidInput from "@/components/form/ValidInput";
import { ValidationObserver } from "vee-validate";
import { getLocalParam, setLocalParam } from "@/vendors/helpers";
import { mapGetters } from "vuex";
import DonutChart from "@/components/charts/DonutChart";
import PagoCard from "@/components/cards/PagoCard";
import EmptyState from "@/components/generals/EmptyState";

export default {
  name: "Caja",
  data() {
    return {
      periodo: "today",
      is_custom: false,
      dias_antes: null,
      dias_despues: null,
      nombre_periodo: null,
      chart_switch: false,
      cartera: true,
      update: 1,
      periodos_dias: [
        {
          dias: "today",
          nombre: "Fecha contable"
        },
        {
          dias: "tomorrow",
          nombre: "+1 día de la fecha contable"
        },
        {
          dias: "yesterday",
          nombre: "-1 día de la fecha contable"
        }
        // {
        //   dias: "week",
        //   nombre: "Esta semana"
        // },
        // {
        //   dias: "month",
        //   nombre: "Este mes"
        // }
      ],
      filtroRealizar: [],
      periodos: null
    };
  },
  components: {
    ValidInput,
    ValidationObserver,
    DonutChart,
    PagoCard,
    EmptyState
  },
  computed: {
    ...mapGetters("pagos", ["getDashboard"]),
    pagos_realizados() {
      return this.getDashboard.pagos_realizados;
    },
    total_pagos_pendientes() {
      return this.getDashboard.pagos_pendientes.disposiciones.concat(
        this.getDashboard.pagos_pendientes.cuentas,
        this.getDashboard.pagos_pendientes.garantias
      );
    },
    pagos_pendientes() {
      if (this.filtroRealizar.length == 0) {
        return this.total_pagos_pendientes;
      }
      let pagos = [];
      this.filtroRealizar.forEach(filtro => {
        if (this.getDashboard.pagos_pendientes[filtro]) {
          pagos.push(...this.getDashboard.pagos_pendientes[filtro]);
        }
      });
      return pagos;
    },
    suma_pagos_realizados() {
      return 0;
    },
    suma_pagos_por_realizar() {
      let pagos = this.total_pagos_pendientes;
      if (pagos.length == 0) {
        return 0;
      }
      pagos = pagos.reduce((prev, pago) => {
        return prev + pago.capital;
      }, 0);
      return pagos;
    },
    outerDatos() {
      return {
        pagos: [
          {
            tipo: "Monto Pagado",
            total: this.getDashboard.total_pagado
          },
          {
            tipo: "Monto por pagar",
            total: this.getDashboard.total_pendiente
          }
        ],
        category: "tipo",
        value: "total",
        key: "pagos"
      };
    },
    innerDatos() {
      let total_data_pendiente = this.total_pagos_pendientes.length;
      let total_data_realizado = this.getDashboard.pagos_realizados.length;
      return {
        totales: [
          {
            tipo: "Pagos realizados",
            total: total_data_realizado
          },
          {
            tipo: "Pagos por realizar",
            total: total_data_pendiente
          }
        ],
        category: "tipo",
        value: "total",
        key: "totales"
      };
    }
  },
  methods: {
    /*
      Función para filtrar pagos a realizar por los tags
      @filtro (String) tipo de documento a mostrar
    */
    toggleFiltroRealizar(filtro) {
      if (this.filtroRealizar.includes(filtro)) {
        let index = this.filtroRealizar.indexOf(filtro);
        this.filtroRealizar.splice(index, 1);
        return;
      }
      this.filtroRealizar.push(filtro);
    },
    /*
      Función para eliminar un periodo custom de favoritos
    */
    deletePeriodoCustom() {
      this.periodos = this.periodos.filter(conf => conf.dias !== this.periodo);
      setLocalParam("periodos", this.periodos);
      this.periodos_dias = this.periodos_dias.filter(
        periodo => periodo.dias !== this.periodo
      );
      this.periodo = "today";
      this.notification(
        "success",
        "Periodo eliminado de favoritos",
        "El periodo se ha eliminado de favoritos"
      );
    },
    /*
      Función para empezar proceso de agregar a favorito, abre un prompt
      @res (Boolean) resultado de validationobeerver
    */
    setPeriodoCustom(res) {
      if (!res) return;
      if (!this.buscarRepetido()) return;
      this.$buefy.dialog.prompt({
        title: "Agregar periodo como favorito",
        message: "¿Cual es el nombre del periodo?",
        inputAttrs: {
          placeholder: "Nombre del periodo...",
          maxlength: 255
        },
        canCancel: ["button"],
        confirmText: "Agregar favorito",
        cancelText: "Cancelar",
        type: "is-primary",
        onConfirm: nombre => this.addFavorito(nombre)
      });
    },
    /*
      función para buscar si el periodo a agregar a favoritos no existe actualmente en base a su rango de días
    */
    buscarRepetido() {
      if (Array.isArray(this.periodos)) {
        if (
          this.periodos.find(
            x => x.dias == this.dias_antes + "-" + this.dias_despues
          )
        ) {
          this.notification(
            "warning",
            "Periodo duplicado",
            "El periodo que intentas agregar a favoritos ya existe"
          );
          return false;
        }
      }
      return true;
    },
    /*
      función para agregar a favorito despues de validación
      @nombre (String) nombre del periodo 
    */
    addFavorito(nombre) {
      if (Array.isArray(this.periodos)) {
        this.periodos.push({
          dias: this.dias_antes + "-" + this.dias_despues,
          nombre: nombre
        });
      } else {
        this.periodos = [
          {
            dias: this.dias_antes + "-" + this.dias_despues,
            nombre: nombre
          }
        ];
      }
      setLocalParam("periodos", this.periodos);
      this.notification(
        "success",
        "Periodo guardado",
        "El periodo se ha guardado como favorito"
      );
      this.periodos_dias.pop();
      this.periodos_dias.push({
        dias: this.dias_antes + "-" + this.dias_despues,
        nombre: nombre
      });
      this.periodos_dias.push({
        dias: "custom",
        nombre: "Crear periodo personalizado"
      });
      this.periodo = this.dias_antes + "-" + this.dias_despues;
      this.is_custom = false;
    },
    /*
      función que se llama al seleccionar un periodo del select
      hace las peticiones a API dependiendo del valor
      @val (String) valor del periodo seleccionado
    */
    setPeriodo(val) {
      this.is_custom = this.periodo == "custom" ? true : false;
      switch (val) {
        case "custom":
          this.is_custom = true;
          break;
        case "tomorrow":
          this.$store.dispatch("pagos/getDashboard", {
            pasivo: this.cartera ? 0 : 1,
            fecha: this.$moment(this.$fecha_cierre)
              .add(1, "days")
              .format("YYYY-MM-DD")
          });
          break;
        case "yesterday":
          this.$store.dispatch("pagos/getDashboard", {
            pasivo: this.cartera ? 0 : 1,
            fecha: this.$moment(this.$fecha_cierre)
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          });
          break;
        case "today":
          this.$store.dispatch("pagos/getDashboard", {
            pasivo: this.cartera ? 0 : 1,
            fecha: this.$moment(this.$fecha_cierre).format("YYYY-MM-DD")
          });
          break;
      }
      this.update++;
    }
  },
  mounted() {
    this.periodos = JSON.parse(getLocalParam("periodos"));
    if(!this.getDashboard){
      this.$store.dispatch("pagos/getDashboard", {
        pasivo: this.cartera ? 0 : 1,
        fecha: this.$moment(this.$fecha_cierre).format("YYYY-MM-DD")
      });
    }
  }
};
</script>
