<template>
  <div
    class="card is-vcentered text-left columns my-1 has-border-radius border shadow-none"
    :class="{
      'has-background-white-ter has-border-white-ter': porPagar,
      'has-background-white': !porPagar
    }"
  >
    <div class="column" :class="{ 'is-4': porPagar, 'is-3': !porPagar }">
      <p class="underline font-bold has-text-grey-darker" v-if="!porPagar">
        Pago #{{ pago.id }}
      </p>
      <p class="underline font-bold has-text-grey-darker text-lg" v-else>
        {{ data.cantidad | currency }}
        <span class="text-sm">{{ data.moneda }}</span>
      </p>
    </div>
    <div class="column is-3">
      <div>
        <p class="has-text-grey-light">{{ data.nombre }}</p>
        <preview
          :id="data.id"
          :color="
            data.operacion == 'Pagar' ? 'has-text-pasivo' : 'has-text-primary'
          "
          :border="
            data.operacion == 'Pagar'
              ? 'has-border-pasivo'
              : 'has-border-primary'
          "
          :tipo="data.tipo"
          >{{ data.idToShow }}</preview
        >
      </div>
    </div>
    <div class="column" v-if="porPagar">
      <p class="has-text-grey-light">Fecha límite</p>
      <p>{{ data.fecha | moment("DD-MM-YYYY") }}</p>
    </div>
    <div class="column" v-else>
      <p class="has-text-grey-light">Monto</p>
      <p>{{ pago.cantidad_pagada | currency }} {{ pago.moneda.clave }}</p>
    </div>
    <div class="column text-right" v-if="!porPagar">
      <!-- se oculta el boton de pagar para las cuentas cobrar pagar, 
        queda pendiente refactorear la parte de las cuentas cobrar pagar 
        en detalle e impresion del ticket -->
      <!-- <b-button
        :type="data.operacion == 'Pagar' ? 'is-pasivo' : 'is-primary'"
        tag="router-link"
        :to="{
          name: 'aplicarpago',
          params: {
            tipo: data.tipo,
            idObjeto: data.id
          }
        }"
        v-if="porPagar"
        >{{ data.operacion }}</b-button
      > -->
      <b-button
        type="is-text"
        tag="router-link"
        :to="{
          name: 'detallepagoroot',
          params: {
            idPago: pago.id
          }
        }"
        icon-right="chevron-right"
        >Ver pago</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "PagoCard",
  props: {
    pago: Object,
    porPagar: Boolean,
    tipo: String
  },
  computed: {
    /*
      Se definen los campos a mostrar dependiendo del tipo de pago que se tiene que realizar
      y si ya fue realizado o aún no
    */
    data() {
      if (this.porPagar) {
        switch (this.tipo) {
          case "cuentaCP":
            return {
              cantidad: this.pago.cantidad_pendiente,
              moneda: this.pago.moneda.clave,
              nombre: this.pago.cobro ? "Cuenta a Cobrar" : "Cuenta a Pagar",
              id: this.pago.id,
              idToShow: this.pago.id,
              tipo: this.tipo,
              fecha: this.pago.fecha_vencimiento,
              operacion: this.pago.cobro ? "Cobrar" : "Pagar"
            };
          case "disposicion":
            return {
              cantidad: this.pago.capital,
              moneda: this.pago.disposicion.moneda.clave,
              nombre: "Disposición",
              id: this.pago.disposicion.id,
              idToShow: this.idToShow(this.pago.disposicion),
              tipo: this.tipo,
              fecha: this.pago.fecha,
              operacion: this.pago.disposicion.pasiva ? "Pagar" : "Cobrar"
            };
        }
      } else {
        if (this.pago.creditos_core_id) {
          return {
            nombre: "Disposición",
            tipo_ruta: "disposicion",
            tipo: "credito",
            id: this.pago.creditos_core_id,
            idToShow: this.idToShow(this.pago.disposicion)
          };
        }
        if (this.pago.garantia.length > 0) {
          return {
            nombre: "Garantía",
            tipo: "garantia",
            tipo_ruta: "garantia",
            id: this.pago.garantia[0].id,
            idToShow: this.pago.garantia[0].id
          };
        }
        if (this.pago.cuenta_c_p.length > 0) {
          return {
            nombre: "Cuenta Cobrar Pagar",
            tipo: "cuentaCP",
            tipo_ruta: "cuenta",
            id: this.pago.cuenta_c_p[0].id,
            idToShow: this.pago.cuenta_c_p[0].id
          };
        }
      }
      return {
        cantidad: 0,
        moneda: "MXN"
      };
    },
    tipoDoc() {
      return "";
    }
  }
};
</script>
