var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card is-vcentered text-left columns my-1 has-border-radius border shadow-none",class:{
    'has-background-white-ter has-border-white-ter': _vm.porPagar,
    'has-background-white': !_vm.porPagar
  }},[_c('div',{staticClass:"column",class:{ 'is-4': _vm.porPagar, 'is-3': !_vm.porPagar }},[(!_vm.porPagar)?_c('p',{staticClass:"underline font-bold has-text-grey-darker"},[_vm._v(" Pago #"+_vm._s(_vm.pago.id)+" ")]):_c('p',{staticClass:"underline font-bold has-text-grey-darker text-lg"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.data.cantidad))+" "),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.data.moneda))])])]),_c('div',{staticClass:"column is-3"},[_c('div',[_c('p',{staticClass:"has-text-grey-light"},[_vm._v(_vm._s(_vm.data.nombre))]),_c('preview',{attrs:{"id":_vm.data.id,"color":_vm.data.operacion == 'Pagar' ? 'has-text-pasivo' : 'has-text-primary',"border":_vm.data.operacion == 'Pagar'
            ? 'has-border-pasivo'
            : 'has-border-primary',"tipo":_vm.data.tipo}},[_vm._v(_vm._s(_vm.data.idToShow))])],1)]),(_vm.porPagar)?_c('div',{staticClass:"column"},[_c('p',{staticClass:"has-text-grey-light"},[_vm._v("Fecha límite")]),_c('p',[_vm._v(_vm._s(_vm._f("moment")(_vm.data.fecha,"DD-MM-YYYY")))])]):_c('div',{staticClass:"column"},[_c('p',{staticClass:"has-text-grey-light"},[_vm._v("Monto")]),_c('p',[_vm._v(_vm._s(_vm._f("currency")(_vm.pago.cantidad_pagada))+" "+_vm._s(_vm.pago.moneda.clave))])]),(!_vm.porPagar)?_c('div',{staticClass:"column text-right"},[_c('b-button',{attrs:{"type":"is-text","tag":"router-link","to":{
        name: 'detallepagoroot',
        params: {
          idPago: _vm.pago.id
        }
      },"icon-right":"chevron-right"}},[_vm._v("Ver pago")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }