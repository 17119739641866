<template>
  <div>
    <div class="w-full h-80 -mt-8" ref="donutchart" />
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import spanish from "@/vendors/es_ES";

am4core.useTheme(am4themes_animated);

export default {
  name: "DonutChart",
  props: {
    outerDatos: Object,
    innerDatos: [Object, null],
    cartera: Boolean
  },
  watch: {
    innerDatos: {
      handler(values) {
        this.innerchart.data = values[values.key];
        this.innerchart.seriesContainer.children.values[1].deepInvalidate();
      },
      deep: true
    },
    outerDatos: {
      handler(values) {
        this.outerchart.data = values[values.key];
      },
      deep: true
    }
  },
  data() {
    return {
      datos_nombre: [],
      chart: null,
      outerchart: null,
      innerchart: null
    };
  },
  methods: {
    /*
      Crea la serie de datos que va en la grafica
      @chart (am4core Chart)
      @data (Object): data recibida de props con datos para renderizar la grafica
      @hasLabel (boolean): Indica si esta grafica tiene un label
    */
    createAxisAndSeries(chart, data, hasLabel) {
      var pieSeries = chart.series.push(new am4charts.PieSeries());
      chart.data = data[data.key];
      pieSeries.dataFields.value = data.value;
      pieSeries.dataFields.category = data.category;
      pieSeries.slices.template.stroke = new am4core.InterfaceColorSet().getFor(
        "background"
      );
      // Disabling labels and ticks on inner circle
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
      pieSeries.slices.template.states.getKey(
        "hover"
      ).properties.shiftRadius = 0;
      pieSeries.slices.template.states.getKey("hover").properties.scale = 1;
      var cs = pieSeries.colors;
      if (hasLabel) {
        pieSeries.radius = am4core.percent(40);
        pieSeries.innerRadius = am4core.percent(30);
        cs.list = this.cartera ? [am4core.color("#1993e9")] : [am4core.color("#ffe12d")];
        cs.stepOptions = {
          lightness: -0.05,
          hue: 0
        };
      } else {
        pieSeries.radius = am4core.percent(40);
        pieSeries.innerRadius = am4core.percent(80);
        cs.list = this.cartera ? [am4core.color("#1993e9")] : [am4core.color("#ffe12d")];
        cs.stepOptions = {
          lightness: -0.05,
          hue: 0
        };
      }
      cs.wrap = false;
      if (hasLabel) {
        var label = chart.seriesContainer.createChild(am4core.Label);
        label.textAlign = "middle";
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.adapter.add("text", function() {
          return (
            "[font-size:18px]Total de pagos[/]:\n[bold font-size:30px]" +
            pieSeries.dataItem.values.value.sum +
            "[/]"
          );
        });
        label.dom.classList.add("has-fill-grey-darker");
      }
    }
  },
  mounted() {
    //Se crea el container el cual puede contener maas de una grafica
    var container = am4core.create(this.$refs.donutchart, am4core.Container);
    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    // Se crea la chart grande que va por afuera con los datos mandados en props
    // de outerDatos
    this.outerchart = container.createChild(am4charts.PieChart);
    this.outerchart.startAngle = 160;
    this.outerchart.endAngle = 380;
    this.outerchart.innerRadius = am4core.percent(40);
    this.outerchart.language.locale = spanish;
    this.outerchart.align = "center";
    this.createAxisAndSeries(this.outerchart, this.outerDatos, false);
    // Se crea la chart pequeña que va por dentro con los datos mandados en props
    // de innerDatos *Esta grafica es opcional*
    if (this.innerDatos != null) {
      this.innerchart = container.createChild(am4charts.PieChart);
      this.innerchart.startAngle = 160;
      this.innerchart.endAngle = 380;
      this.innerchart.innerRadius = am4core.percent(40);
      this.innerchart.language.locale = spanish;
      this.innerchart.width = am4core.percent(95);
      this.innerchart.paddingLeft = am4core.percent(5);
      this.innerchart.paddingTop = am4core.percent(4);
      this.createAxisAndSeries(this.innerchart, this.innerDatos, true);
    }
    this.chart = container;
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>
